const cookie_name: string = 'wisewave_tracking_consent';
enum CookieOptions {
    All = "all",
    Necessary = "necessary",
}

const setCookie = (name: string, value: string, expireInSeconds: number) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (expireInSeconds * 1000));
    const expires = `expires=${expirationDate.toUTCString()}`;
    document.cookie = `${name}=${encodeURIComponent(value)}; ${expires}; path=/; SameSite=Strict`;
}

const getCookie = (name: string): string | null  => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.trim().split('='); 
        if (cookieName === name) {
            return decodeURIComponent(cookieValue);
        }
    }
    return null;
}

const run = () => {
    const divElement = document.getElementById('consent_box');
    const allElement = document.getElementById('consent_box_all');
    const necessaryElement = document.getElementById('consent_box_necessary');
    const refuseElement = document.getElementById('consent_box_no');
    const cookie = getCookie(cookie_name);
    if (
        divElement !== null 
        && allElement !== null 
        && necessaryElement !== null 
        && refuseElement !== null 
        && cookie === null
    ) {
        divElement.style.display = 'block';
        allElement.addEventListener('click', () => {
            setCookie(cookie_name, CookieOptions.All, 31536000);
            grant_analytics();
            divElement.style.display = 'none';
        });
        necessaryElement.addEventListener('click', () => {
            setCookie(cookie_name, CookieOptions.Necessary, 31536000);
            divElement.style.display = 'none';
        });
        refuseElement.addEventListener('click', () => {
            divElement.style.display = 'none';
        });
    }
}

run();
